import Button from '@/components/atoms/Button'
import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import {LinkType} from '@/components/types/link'
import cn from 'classnames'
import Image from 'next/image'
import styles from './ImageText.module.scss'
/**
 *
 * @param  props0
 * @param  props0.block
 */

type ImageTextProps = {
  block: {
    removePaddingBottom: boolean
    cards: [
      {
        image: ImageType
        link: LinkType
        title: string
        text: string
        label: string
      }
    ]
  }
}
export default function ImageText({block}: ImageTextProps) {
  const {cards, removePaddingBottom} = block
  return (
    <Container>
      {cards?.length > 0 &&
        cards.map((card, index) => (
          <div
            key={index}
            className={cn(
              styles.imageTextWrapper,
              cards.length === 1 ? styles.onlyChild : '',
              removePaddingBottom ? styles.removePaddingBottom : ''
            )}
          >
            <div className={styles.textWrapper}>
              <div className={styles.textContainer}>
                {card?.label?.length > 0 && (
                  <div data-aos="fade-up" className={styles.label}>
                    {card.label}
                  </div>
                )}
                <div data-aos="fade-up" className={styles.title}>
                  {card.title}
                </div>
                <div
                  data-aos="fade-up"
                  className={styles.text}
                  dangerouslySetInnerHTML={{__html: card.text}}
                />
                {card?.link?.url && (
                  <Button
                    attributes={{'data-aos': 'fade-up'}}
                    type="blue"
                    className={styles.button}
                    text={card.link.title}
                    icon="arrowOutWard"
                    iconSize={42}
                    url={card.link.url}
                    urlExternal={card.link.target}
                  />
                )}
              </div>
            </div>
            {card.image.mediaItemUrl && (
              <div className={styles.imageContainer}>
                <Image
                  data-aos="fade-in"
                  src={card.image.mediaItemUrl}
                  alt={card.image.altText}
                  width={card.image.mediaDetails.width}
                  height={card.image.mediaDetails.height}
                  layout="responsive"
                  objectFit="cover"
                />
              </div>
            )}
          </div>
        ))}
    </Container>
  )
}
